.enterpriseInternalTrainingOrder .itemLabel {
  min-width: 6rem;
}
.amountSummary {
  padding-left: 12px;
}
.amountSummary span {
  color: red;
}
.btnBox {
  display: flex;
  justify-content: center;
}
